import React from 'react'
import { createRoot } from 'react-dom/client';
import { Controller } from '@hotwired/stimulus';
import Feedback from '../components/feedback';

// Connects to data-controller="feedback"
export default class extends Controller {
  static values = {
    mobile: Boolean,
    projectId: String,
    projectName: String,
    email: String,
    feedbackableId: String,
    feedbackableType: String,
    pathPrefix: String,
    privacyPolicyUrl: String,
    termsAndConditionsUrl: String,
    sentiment: String,
    theme: Object,
    canLeaveFeedback: Boolean,
    recaptchaSiteToken: String,
    translations: Object
  };

  connect() {
    const root = createRoot(this.element);

    return root.render(
      <Feedback
        mobile={this.mobileValue}
        projectId={this.projectIdValue}
        email={this.emailValue}
        feedbackableId={this.feedbackableIdValue}
        feedbackableType={this.feedbackableTypeValue}
        privacyPolicyUrl={this.privacyPolicyUrlValue}
        termsAndConditionsUrl={this.termsAndConditionsUrlValue}
        sentiment={this.sentimentValue}
        theme={this.themeValue}
        pathPrefix={this.pathPrefixValue}
        canLeaveFeedback={this.canLeaveFeedbackValue}
        projectName={this.projectNameValue}
        recaptchaSiteToken={this.recaptchaSiteTokenValue}
        translations={this.translationsValue}
      />
    );
  }
}
