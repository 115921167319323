import { Controller } from "@hotwired/stimulus"
import React from 'react';
import { createRoot } from 'react-dom/client';
import RssFeedButton from '../components/RssFeedButton';

// Connects to data-controller="rss-feed"
export default class extends Controller {
  static values = {
    url: String,
    translations: Object
  }


  connect() {
    const root = createRoot(this.element);

    return root.render(
      <RssFeedButton
        url={this.urlValue}
        translations={this.translationsValue}
      />
    );
  }
}
