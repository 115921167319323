import { Controller } from '@hotwired/stimulus';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Ideas from '../components/Ideas';

// Connects to data-controller="ideas"
export default class extends Controller {
  static values = {
    development: Boolean,
    projectId: String,
    projectName: String,
    pathPrefix: String,
    canVote: Boolean,
    voteCountDisplay: String,
    votingEnabled: Boolean,
    feedbackEnabled: Boolean,
    recaptchaSiteToken: String,
    emptyStateImage: String,
    privacyPolicyUrl: String,
    termsAndConditionsUrl: String,
    theme: Object,
  };

  connect() {
    const root = createRoot(this.element);

    return root.render(
      <Ideas
        development={this.developmentValue}
        projectId={this.projectIdValue}
        projectName={this.projectNameValue}
        pathPrefix={this.pathPrefixValue}
        canVote={this.canVoteValue}
        voteCountDisplay={this.voteCountDisplayValue}
        votingEnabled={this.votingEnabledValue}
        feedbackEnabled={this.feedbackEnabledValue}
        recaptchaSiteToken={this.recaptchaSiteTokenValue}
        emptyStateImage={this.emptyStateImageValue}
        privacyPolicyUrl={this.privacyPolicyUrlValue}
        termsAndConditionsUrl={this.termsAndConditionsUrlValue}
        theme={this.themeValue}
      />
    );
  }
}
