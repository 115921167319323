import React from 'react';
import SpeechBubble from './assets/SpeechBubble';

import Happy from './assets/Happy';
import Meh from './assets/Meh';
import Sad from './assets/Sad';

import styled from '@emotion/styled';

const BillboardDiv = styled.div`
  border-radius: 4px;
  padding: 1rem;
  color: #FFFFFF;
  background-color: ${({ theme }) => theme.primaryColor};
  &:hover {
    cursor:pointer;
  }
  .reactions  {
    display: table;
    margin: 0 auto;
    span { margin: 0 5px; }
  }
  @media (max-width: 1280px) {
    .reactions {
      span { margin: 0 4px; }
    }
  }
`;

const ImportanceText = ({ title, cta }) => (
  <div>
    <div className="pb-1">{title}</div>
    <span className="feedback-billboard flex-billboard">
      <div className="mr-2"><SpeechBubble /></div>
      <div><strong>{cta}</strong></div>
    </span>
  </div>
);


const MobileBillboard = ({
  sentiment,
  target
}) => {
  switch (sentiment) {
    case 'reaction':
      return (
        <button
          type="button"
          className="feedback-billboard btn btn-primary"
          data-toggle="modal"
          data-target={target}
        >
          <SpeechBubble />
          <strong className="ml-2">Feedback</strong>
        </button>
      )

    case 'importance':
      return (
        <span
          className="d-flex justify-content-center"
          data-toggle="modal"
          data-target={target}
        >
          <ImportanceText />
        </span>
      );
  }
}

const Billboard = ({
  feedbackableType,
  mobile,
  sentiment,
  target,
  theme,
  translations
}) => {
  if (mobile === true) {
    return (
      <MobileBillboard sentiment={sentiment} />
    )
  }

  return (
    <BillboardDiv
      data-toggle="modal"
      data-target={target}
      theme={theme}
      onClick={() => {
        setTimeout(function() {
          if (target === "#feedbackModal") {
            document.getElementById('feedback-input').focus()
          }
        }, 500);
      }}
    >
    { sentiment === 'importance' ? (
      <ImportanceText title={translations.importanceTitle} cta={translations.importanceCTA} />
    ) : (
      <>
        <div className="prompt">
          What do you think of this {feedbackableType === 'Announcement' ? 'announcement' : 'roadmap item'}?
        </div>
        <div className="reactions">
          <span className="happy">
            <Happy
              onClick={() => send({ type: 'reaction.UPDATE', value: 'happy' })}
              selected={false}
              fill="white"
              style={{ fontSize: 30 }}
            />
          </span>
          <span className="meh">
            <Meh
              selected={false}
              fill="white"
              style={{ fontSize: 30 }}
              onClick={() => send({ type: 'reaction.UPDATE', value: 'meh' })}
            />
          </span>
          <span className="sad">
            <Sad
              selected={false}
              fill="white"
              style={{ fontSize: 30 }}
              onClick={() => send({ type: 'reaction.UPDATE', value: 'sad' })}
            />
          </span>
        </div>
      </>
    )}
    </BillboardDiv>
  )
};

export default Billboard;