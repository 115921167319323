import React from 'react';
import ImportanceSelector from './ImportanceSelector';

import Low from 'components/icons/Low';
import Medium from 'components/icons/Medium';
import High from 'components/icons/High';

const Importance = ({ current, send, theme }) => (
  <>
    <label>How important is this to you?</label>
    <div className="importance-field row m-0 py-2justify-content-center" style={{ width: '100%', marginLeft: '-.05rem' }}>
      <ImportanceSelector
        send={send}
        value="low"
        selected={current.context.importance === 'low'}
        primaryColor={theme.primaryColor}
        label="Nice to have"
        image={<Low style={{ fontSize: 16, marginBottom: '-14px' }} selected={current.context.importance === 'low'} theme={theme} />}
      />
      <ImportanceSelector
        send={send}
        value="medium"
        selected={current.context.importance === 'medium'}
        primaryColor={theme.primaryColor}
        label="Somewhat"
        image={<Medium style={{ fontSize: 18, marginBottom: '-8px' }} selected={current.context.importance === 'medium'} theme={theme} />}
      />
      <ImportanceSelector
        send={send}
        value="high"
        selected={current.context.importance === 'high'}
        primaryColor={theme.primaryColor}
        label="Extremely"
        image={<High style={{ fontSize: 24, marginBottom: 2 }} selected={current.context.importance === 'high'} theme={theme} />}
      />
    </div>
  </>
);

export default Importance;