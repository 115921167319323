/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import { Machine, assign } from 'xstate';
import { useMachine } from '@xstate/react';

import FeedbackModal from './FeedbackModal';

import AuthPromptModalBody from './AuthPromptModalBody';
import LeaveFeedbackModalBody from './LeaveFeedbackModalBody';

import Billboard from './Billboard';

const DEFAULT_THEME = {
  primaryColor: '#4B5C99',
  secondaryColor: '',
  primaryTextColor: '#2A3760',
  secondaryTextColor: '#BAB9B9',
  whiteColor: '#FFFFFF',
  offWhiteColor: '#F3F6F8',
  lightGrayColor: '#E8EBEF',
  grayColor: '#D4D5D8',
};

const DefaultContext = {
  content: null,
  reaction: null,
  importance: null,
};

const feedbackMachine = Machine({
  id: 'feedback',
  context: DefaultContext,
  initial: 'formOpen',
  states: {
    ready: {
      on: {
        OPEN: 'formOpen',
      },
    },
    formOpen: {
      initial: 'dataEntry',
      states: {
        dataEntry: {},
      },
      on: {
        'reaction.UPDATE': {
          actions: assign({
            reaction: (_, event) => event.value,
          }),
        },
        'importance.UPDATE': {
          actions: assign({
            importance: (_, event) => event.value,
          }),
        },
        'content.UPDATE': {
          actions: assign({
            content: (_, event) => event.value,
          }),
        },
        SUBMIT: {
          target: 'submitted',
        },
        CANCEL: {
          target: 'ready',
          actions: [
            assign(() => DefaultContext),
          ],
        },
      },
    },
    submitted: {
      on: {
        CLOSE: {
          target: 'formOpen',
          actions: [
            assign(() => DefaultContext),
          ],
        },
      },
    },
  },
});

const Feedback = (props) => {
  const [current, send] = useMachine(feedbackMachine);

  return (
    <>
      <FeedbackModal>
        { props.canLeaveFeedback ? (
          <LeaveFeedbackModalBody
            {...props}
            target="#feedbackModal"
            current={current}
            send={send}
          />
        ) : (
          <AuthPromptModalBody
            projectName={props.projectName}
            recaptchaSiteToken={props.recaptchaSiteToken}
            theme={props.theme}
          />
        )}
      </FeedbackModal>


      <Billboard
        current={current}
        feedbackableType={props.feedbackableType}
        send={send}
        sentiment={props.sentiment}
        target={props.canLeaveFeedback ? "#feedbackModal" : "#feedback-auth-modal"}
        theme={props.theme}
        translations={props.translations}
      />
    </>
  );
};

export default Feedback;

Feedback.defaultProps = {
  sentiment: 'reaction',
  theme: DEFAULT_THEME,
};

Feedback.propTypes = {
  mobile: PropTypes.bool,
  sentiment: PropTypes.string,
  email: PropTypes.string,
  theme: PropTypes.shape({
    optionalProperty: PropTypes.string,
  }),
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  pathPrefix: PropTypes.string,
  feedbackableId: PropTypes.string,
  feedbackableType: PropTypes.string,
  canLeaveFeedback: PropTypes.bool,
  recaptchaSiteToken: PropTypes.string,
  translations: PropTypes.object
};
