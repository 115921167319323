import React from 'react';
import Wrapper from '../feedback/assets/ReactionWrapper';

const Unselected = ({ onClick, onKeyPress, theme, ...props }) => (
  <Wrapper onClick={onClick} onKeyPress={onKeyPress}>
    <svg {...props} width="1em" height=".64em" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#2E9E59" d="M9.25 6C9.03906 6 8.85156 5.92969 8.71094 5.78906L5.5 2.57812L2.26562 5.78906C1.98438 6.09375 1.49219 6.09375 1.21094 5.78906C0.90625 5.50781 0.90625 5.01562 1.21094 4.73438L4.96094 0.984375C5.24219 0.679688 5.73438 0.679688 6.01562 0.984375L9.76562 4.73438C10.0703 5.01562 10.0703 5.50781 9.76562 5.78906C9.625 5.92969 9.4375 6 9.25 6Z" />
    </svg>
  </Wrapper>
);

const Selected = ({ onClick, onKeyPress, theme, ...props }) => (
  <Wrapper onClick={onClick} onKeyPress={onKeyPress}>
    <svg {...props} width="1em" height=".64em" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={theme.primaryColor} d="M9.25 6C9.03906 6 8.85156 5.92969 8.71094 5.78906L5.5 2.57812L2.26562 5.78906C1.98438 6.09375 1.49219 6.09375 1.21094 5.78906C0.90625 5.50781 0.90625 5.01562 1.21094 4.73438L4.96094 0.984375C5.24219 0.679688 5.73438 0.679688 6.01562 0.984375L9.76562 4.73438C10.0703 5.01562 10.0703 5.50781 9.76562 5.78906C9.625 5.92969 9.4375 6 9.25 6Z" />
    </svg>
  </Wrapper>
);

const Low = ({ selected, ...props }) => {
  if (selected) {
    return <Selected {...props} />;
  }

  return <Unselected {...props} />;
};

export default Low;
