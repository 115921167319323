import React, { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import useSubmitFeedback from "../core/hooks/useSubmitFeedback";
import Liftoff from '../icons/Liftoff';

import Reaction from './Reaction';
import Importance from './LeaveFeedback/Importance';

import { useKeyPress } from 'ahooks';

const Card = styled.div`
  transition: height 100ms;
`;

const LeaveFeedbackModalBody = ({
  email,
  current,
  feedbackableId,
  feedbackableType,
  privacyPolicyUrl,
  projectId,
  pathPrefix,
  send,
  sentiment,
  termsAndConditionsUrl,
  theme
}) => {
  const [submitFeedback] = useSubmitFeedback(!!pathPrefix ? `${pathPrefix}/feedbacks.json` : "/feedbacks.json");

  const { reset, register, handleSubmit } = useForm();

  const onSubmit = useCallback((data) => {
    const values = {
      project: projectId,
      feedbackable_id: feedbackableId,
      feedbackable_type: feedbackableType,
      reaction: current.context.reaction,
      importance: current.context.importance,
      content: current.context.content,
      ...data,
    };

    if (email) {
      values.email = email;
    }

    send('SUBMIT');
    reset();

    return submitFeedback(values);
  }, [current.context.reaction, current.context.importance]);

  useKeyPress('enter', () => email && handleSubmit());

  return (
    <div className="modal fade" id="feedbackModal" tabIndex={-1} aria-labelledby="feedbackModalLabel">
      <div className="modal-dialog">
        <div className="modal-content feedback-modal px-3 py-3">
          { current.matches('formOpen') && (
            <>
              <div className="modal-header px-0 py-0 mx-3 mt-2 mb-3">
                <div className="d-flex flex-column w-100">
                  <h3 className="mt-3 text-center font-weight-bold primary-text-color" id="feedbackModalLabel">Share your thoughts</h3>
                  <p className="mb-2 text-center">What kind of feedback do you have?</p>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body px-0 py-0">
                <Card className="px-3">
                  { sentiment === 'reaction' && (
                    <Reaction
                      current={current}
                      send={send}
                      theme={theme}
                    />
                  )}
    
                  <form onSubmit={handleSubmit(onSubmit)} className="feedback-form">
                    <div className="form-group mb-4">
                      <label htmlFor="content">Short summary of your feedback:</label>
                      <textarea
                        {...register('content', { required: true })}
                        onChange={(e) => {
                          send({ type: 'content.UPDATE', value: e.target.value });
                        }}
                        className="form-control"
                        required
                        rows="6"
                        id='feedback-input'
                      />
                    </div>
    
                    {sentiment === 'importance' && <Importance current={current} send={send} theme={theme} />}
    
                    {!email && (
                      <div className="form-group mt-3">
                        <label htmlFor="email">
                          What's your email address?
                        </label>
                        <input
                          {...register('email', { required: true })}
                          type="email"
                          className="form-control"
                          required
                        />
                        <small id="emailHelp" className="form-text text-muted">Verify your email to send the team your feedback</small>
                      </div>
                    )}
    
                    <div className="form-group mt-3 mb-0">
                      <button
                        type="submit"
                        className="feedback-submit btn btn-primary btn-block mb-0"
                        style={{
                          backgroundColor: theme.primaryColor,
                          borderColor: theme.primaryColor,
                          color: theme.whiteColor,
                        }}
                      >
                        Submit
                      </button>
                    </div>
    
                    <div className="form-group mt-3">
                      <small className="text-secondary d-block" style={{ lineHeight: '1.25rem' }}>
                        By clicking submit, you accept our{' '}
                        <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">privacy policy</a> and{' '}
                        <a href={termsAndConditionsUrl} target="_blank" rel="noopener noreferrer"> terms and conditions</a>, reCAPTCHA{' '}
                        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">privacy</a> and{' '}
                        <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">terms</a>.
                      </small>
                    </div>
                  </form>
                </Card>
              </div>
            </>
          )}
    
          { current.matches('submitted') && (
            <div className="modal-body px-0 py-0">
              <div className="text-center p-4">
                <Liftoff style={{ fontSize: 79 }} />
                <h3 className="mt-3">We got it!</h3>
                <h2 className="mt-2">Thank you!</h2>
                <button
                  type="button"
                  onClick={() => setTimeout(() => send({ type: 'CLOSE' }), 500)}
                  className="btn btn-primary btn-block mt-4"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LeaveFeedbackModalBody;
