import React from 'react';
import Happy from './assets/Happy';
import Meh from './assets/Meh';
import Sad from './assets/Sad';

const Reaction = ({ current, send, theme }) => (
  <div>
    <div className="d-flex flex-row px-4 my-3">
      <div className="col text-center">
        <Happy
          onClick={() => send({ type: 'reaction.UPDATE', value: 'happy' })}
          selected={current.context.reaction === 'happy'}
          theme={theme}
        />
      </div>
      <div className="col text-center">
        <Meh
          onClick={() => send({ type: 'reaction.UPDATE', value: 'meh' })}
          selected={current.context.reaction === 'meh'}
          theme={theme}
        />
      </div>
      <div className="col text-center">
        <Sad
          onClick={() => send({ type: 'reaction.UPDATE', value: 'sad' })}
          selected={current.context.reaction === 'sad'}
          theme={theme}
        />
      </div>
    </div>
  </div>
);

export default Reaction;