import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import queryString from 'query-string';
import cx from 'classnames';

export const Container = styled.div`
  border-radius: 6px;
`;

export const Category = styled.span`
  padding: 8px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.1;
  border: none;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 8px 12px 0;
  transition:
    background 100ms,
    color 100ms;
  &:hover {
    opacity: 0.9;
  }
`;

export const CategoriesHeading = ({ title, cta, clear }) => (
  <div className='heading clearfix px-3 pt-3'>
    <div
      className='float-left d-flex align-items-center primary-text-color'
      style={{ fontSize: 16 }}
    >
      <strong>{title}</strong>
    </div>
    <div className='float-right'>
      <a className='primary-text-color' href='#' onClick={clear}>
        <small>{cta}</small>
      </a>
    </div>
  </div>
);

export const ChangeTypesHeading = ({ title, cta, clear }) => (
  <div className='heading clearfix px-3 pt-3'>
    <div
      className='float-left d-flex align-items-center primary-text-color'
      style={{ fontSize: 16 }}
    >
      <strong>{title}</strong>
    </div>
    <div className='float-right'>
      <a className='primary-text-color' href='#' onClick={clear}>
        <small>{cta}</small>
      </a>
    </div>
  </div>
);

export const CategoriesCloud = ({ value, categories, onToggle, ...props }) => {
  return (
    <div className='categories pt-3 px-3 pb-2' {...props}>
      {categories.map(({ id, name, description }) => (
        <Category
          key={id}
          data-title={description}
          onClick={() => onToggle([id])}
          style={{
            backgroundColor: value.has(id) ? null : 'rgba(255, 255, 255, 0.25)',
          }}
          className={cx({
            'primary-text-color': !value.has(id),
            'white-color primary-background': value.has(id),
          })}
          selected={value.has(id)}
        >
          {name}
        </Category>
      ))}
    </div>
  );
};

export const ChangeTypeSelector = ({
  changeTypes,
  onToggle,
  value,
  ...props
}) => {
  return (
    <div className='categories pt-3 px-3 pb-2' {...props}>
      {changeTypes.map(({ id, name }) => (
        <Category
          key={id}
          data-title={name}
          onClick={() => onToggle([id])}
          style={{
            backgroundColor: value.has(id) ? null : 'rgba(255, 255, 255, 0.25)',
          }}
          className={cx({
            'primary-text-color': !value.has(id),
            'white-color primary-background': value.has(id),
          })}
          selected={value.has(id)}
        >
          {name}
        </Category>
      ))}
    </div>
  );
};

const ReleasesFilter = ({
  project,
  categoriesHidden,
  changeTypesHidden,
  selectedCategories,
  selectedChangeTypes,
  changeTypeRollout,
  view,
  location,
  translations,
}) => {
  const { categories, change_types } = project;
  const [filterCategories, updateFilterCategories] = useState(
    new Set(selectedCategories || [])
  );
  const [filterTypes, updateFilterTypes] = useState(
    new Set(selectedChangeTypes || [])
  );

  const toggleFilter = useCallback(
    (ids, isChangeType = false) => {
      const newFilteredItems = new Set(
        isChangeType ? filterTypes : filterCategories
      );
      ids = Array.isArray(ids) ? ids : [ids];

      ids.forEach((id) => {
        if (newFilteredItems.has(id)) {
          newFilteredItems.delete(id);
        } else {
          newFilteredItems.add(id);
        }
      });

      const searchTerms = {
        view: view.slug,
      };

      if (isChangeType) {
        updateFilterTypes(newFilteredItems);

        if (newFilteredItems.size > 0) {
          searchTerms.change_types = Array.from(newFilteredItems).join(',');
        }

        if (filterCategories.size > 0) {
          searchTerms.categories = Array.from(filterCategories).join(',');
        }
      } else {
        updateFilterCategories(newFilteredItems);
        if (newFilteredItems.size > 0) {
          searchTerms.categories = Array.from(newFilteredItems).join(',');
        }

        if (filterTypes.size > 0) {
          searchTerms.change_types = Array.from(filterTypes).join(',');
        }
      }

      const currentParams = new URLSearchParams(window.location.search);
      if (currentParams.has('search')) {
        searchTerms.search = currentParams.get('search');
      }

      const search = queryString.stringify(searchTerms);

      const frame = document.querySelector('turbo-frame#outlet');
      if (frame) {
        frame.src = `${location}?${search}`;
        const newURL = `${window.location.protocol}//${window.location.host}${location}?${search}`;
        window.history.pushState({}, '', newURL);
        window.Turbo.navigator.history.push(newURL);
      }
    },
    [
      filterTypes,
      filterCategories,
      updateFilterTypes,
      updateFilterCategories,
      view.slug,
      location,
      change_types,
    ]
  );

  const toggleChangeType = useCallback(
    (changeTypeIds) => toggleFilter(changeTypeIds, true),
    [toggleFilter]
  );
  const toggleCategory = useCallback(
    (categoryIds) => toggleFilter(categoryIds, false),
    [toggleFilter]
  );

  const clearFilter = useCallback(
    (e, isChangeType) => {
      e.preventDefault();
      const searchTerms = {
        view: view.slug,
      };
      const currentParams = new URLSearchParams(window.location.search);
      if (currentParams.has('search')) {
        searchTerms.search = currentParams.get('search');
      }

      // Keep the other filter if clearing only one type
      if (isChangeType && filterCategories.size > 0) {
        searchTerms.categories = Array.from(filterCategories).join(',');
      } else if (!isChangeType && filterTypes.size > 0) {
        searchTerms.change_types = Array.from(filterTypes).join(',');
      }

      const search = queryString.stringify(searchTerms);

      const frame = document.querySelector('turbo-frame#outlet');
      if (frame) {
        frame.src = `${location}?${search}`;
        const newURL = `${window.location.protocol}//${window.location.host}${location}?${search}`;
        window.history.pushState({}, '', newURL);
        window.Turbo.navigator.history.push(newURL);
      }

      // Update the filter state
      if (isChangeType) {
        updateFilterTypes(new Set());
      } else {
        updateFilterCategories(new Set());
      }
    },
    [view, filterCategories, filterTypes, updateFilterTypes, updateFilterCategories, location]
  );

  return (
    <>
      <Container
        className='light-gray-background'
        style={{ marginBottom: '12px' }}
      >
        {categories && !categoriesHidden && categories.length > 0 && (
          <>
            <CategoriesHeading
              title={translations.categoriesTitle}
              cta={translations.categoriesClear}
              clear={(event) => {
                clearFilter(event, false);
              }}
            />
            <CategoriesCloud
              categories={categories}
              onToggle={toggleCategory}
              value={filterCategories}
            />
          </>
        )}
      </Container>
      {changeTypeRollout && !changeTypesHidden && change_types.length > 0 && (
        <Container className='light-gray-background'>
          <ChangeTypesHeading
            title={translations.labelsTitle}
            cta={translations.categoriesClear}
            clear={(event) => {
              clearFilter(event, true);
            }}
          />
          <ChangeTypeSelector
            changeTypes={change_types}
            onToggle={toggleChangeType}
            value={filterTypes}
          />
        </Container>
      )}
    </>
  );
};

export default ReleasesFilter;
