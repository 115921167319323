import React from 'react';
import { createPortal } from 'react-dom';

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.root = document.getElementById('main');
    this.el = document.getElementById('feedback-portal-root');
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentWillUnmount() {
    if (this.root.contains(this.el)) {
      this.root.removeChild(this.el);
    }
  }

  render() {
    const { props, el } = this;
    return createPortal(props.children, el);
  }
};

export default FeedbackModal