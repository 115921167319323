import React from 'react';
import Wrapper from '../feedback/assets/ReactionWrapper';

const Unselected = ({ onClick, onKeyPress, theme, ...props }) => (
  <Wrapper onClick={onClick} onKeyPress={onKeyPress}>
    <svg {...props} width="1em" height="1em" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#CFAA34" d="M2.26562 5.28906L5.5 2.07812L8.71094 5.28906C8.85156 5.42969 9.03906 5.5 9.25 5.5C9.4375 5.5 9.625 5.42969 9.76562 5.28906C10.0703 5.00781 10.0703 4.51562 9.76562 4.23438L6.01562 0.484375C5.73438 0.179688 5.24219 0.179688 4.96094 0.484375L1.21094 4.23438C0.90625 4.51562 0.90625 5.00781 1.21094 5.28906C1.49219 5.59375 1.98438 5.59375 2.26562 5.28906ZM6.01562 4.98438C5.73438 4.67969 5.24219 4.67969 4.96094 4.98438L1.21094 8.73438C0.90625 9.01562 0.90625 9.50781 1.21094 9.78906C1.49219 10.0938 1.98438 10.0938 2.26562 9.78906L5.5 6.57812L8.71094 9.78906C8.85156 9.92969 9.03906 10 9.25 10C9.4375 10 9.625 9.92969 9.76562 9.78906C10.0703 9.50781 10.0703 9.01562 9.76562 8.73438L6.01562 4.98438Z" />
    </svg>
  </Wrapper>
);

const Selected = ({ onClick, onKeyPress, theme, ...props }) => (
  <Wrapper onClick={onClick} onKeyPress={onKeyPress}>
    <svg {...props} width="1em" height="1em" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={theme.primaryColor} d="M2.26562 5.28906L5.5 2.07812L8.71094 5.28906C8.85156 5.42969 9.03906 5.5 9.25 5.5C9.4375 5.5 9.625 5.42969 9.76562 5.28906C10.0703 5.00781 10.0703 4.51562 9.76562 4.23438L6.01562 0.484375C5.73438 0.179688 5.24219 0.179688 4.96094 0.484375L1.21094 4.23438C0.90625 4.51562 0.90625 5.00781 1.21094 5.28906C1.49219 5.59375 1.98438 5.59375 2.26562 5.28906ZM6.01562 4.98438C5.73438 4.67969 5.24219 4.67969 4.96094 4.98438L1.21094 8.73438C0.90625 9.01562 0.90625 9.50781 1.21094 9.78906C1.49219 10.0938 1.98438 10.0938 2.26562 9.78906L5.5 6.57812L8.71094 9.78906C8.85156 9.92969 9.03906 10 9.25 10C9.4375 10 9.625 9.92969 9.76562 9.78906C10.0703 9.50781 10.0703 9.01562 9.76562 8.73438L6.01562 4.98438Z" />
    </svg>
  </Wrapper>
);

const Medium = ({ selected, ...props }) => {
  if (selected) {
    return <Selected {...props} />;
  }

  return <Unselected {...props} />;
};

export default Medium;
