import React from 'react';
import styled from '@emotion/styled';

const StyledSpan = styled.span`
  outline: none;
  cursor: pointer;
  font-size: 50px;
  line-height: 1;
  
  svg {
    transition: all .1s ease-in-out;
    &:hover { transform: scale(1.05); }
    &:active { transform: scale(0.95); }
  }
`;

const ReactionWrapper = ({ children, ...props }) => (
  // eslint-disable-next-line react/destructuring-assignment
  (<StyledSpan onClick={props.onClick} onKeyPress={props.onKeyPress} role="button" tabIndex="0">
    {children}
  </StyledSpan>)
);

export default ReactionWrapper;
