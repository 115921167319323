import React from 'react';

const ImportanceSelector = ({
  send,
  selected,
  primaryColor,
  value,
  label,
  image
}) => {
  const buttonStyles = {
    flexWrap: 'wrap',
    minWidth: '125px',
    border: '1px solid #dee2e6',
    borderRadius: '0.25rem',
  };

  const buttonSelectedStyles = {
    flexWrap: 'wrap',
    minWidth: '125px',
    backgroundColor: `${primaryColor}22`,
    border: `2px solid ${primaryColor}`,
    borderRadius: '0.25rem',
  };

  const labelStyles = {
    color: `${primaryColor}`,
    fontWeight: 'bold',
  };

  return (
    <div
      className="importance-button d-flex align-items-center col-sm-1 my-2 mr-3 pl-3 pr-1 pb-2"
      style={selected ? buttonSelectedStyles : buttonStyles}
      onClick={() => send({ type: 'importance.UPDATE', value })}
    >
      <div className="importance-image" style={{ width: '33px' }}>
        {image}
      </div>
      <div className="importance-label" style={selected ? labelStyles : {}}>
        {label}
      </div>
    </div>
  );
};

export default ImportanceSelector;