import React from 'react';

const SpeechBubble = ({ fill, ...props }) => (
  <svg style={{marginBottom:"3px"}} width="1.167em" height="1em" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.5 0.4375C8.66797 0.4375 9.75977 0.691406 10.75 1.14844C11.7402 1.63086 12.5273 2.26562 13.1113 3.07812C13.6953 3.89062 14 4.75391 14 5.71875C14 6.68359 13.6953 7.57227 13.1113 8.38477C12.5273 9.19727 11.7402 9.83203 10.75 10.2891C9.75977 10.7715 8.66797 11 7.5 11C6.6875 11 5.90039 10.8984 5.13867 10.6445C4.02148 11.4316 2.85352 11.8125 1.60938 11.8125C1.48242 11.8125 1.38086 11.7871 1.2793 11.7109C1.17773 11.6602 1.10156 11.5586 1.05078 11.457C1 11.3555 0.974609 11.2285 1 11.1016C1.02539 11 1.07617 10.8984 1.15234 10.8223C1.22852 10.7461 1.38086 10.543 1.60938 10.2383C1.93945 9.78125 2.19336 9.34961 2.3457 8.91797C1.43164 7.97852 1 6.91211 1 5.71875C1 4.75391 1.2793 3.89062 1.86328 3.07812C2.44727 2.26562 3.23438 1.63086 4.22461 1.14844C5.21484 0.691406 6.30664 0.4375 7.5 0.4375ZM7.5 9.78125C8.43945 9.78125 9.32812 9.60352 10.1406 9.24805C10.9531 8.89258 11.5879 8.38477 12.0703 7.75C12.5273 7.14062 12.7812 6.45508 12.7812 5.71875C12.7812 5.00781 12.5273 4.32227 12.0703 3.6875C11.5879 3.07812 10.9531 2.57031 10.1406 2.21484C9.32812 1.85938 8.43945 1.65625 7.5 1.65625C6.53516 1.65625 5.67188 1.85938 4.85938 2.21484C4.04688 2.57031 3.38672 3.07812 2.92969 3.6875C2.44727 4.32227 2.21875 5.00781 2.21875 5.71875C2.21875 6.58203 2.54883 7.36914 3.23438 8.08008L3.74219 8.63867L3.48828 9.34961C3.33594 9.70508 3.18359 10.0352 2.98047 10.3906C3.48828 10.2383 3.9707 9.98438 4.42773 9.62891L4.93555 9.29883L5.51953 9.47656C6.1543 9.67969 6.81445 9.78125 7.5 9.78125Z"
      fill={fill || 'white'}
    />
  </svg>
);

export default SpeechBubble;
