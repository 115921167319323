import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import {
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
const { Text, Title } = Typography;

import { useKeyPress } from 'ahooks';

const AuthPromptModalBody = (props) => {
  const {
    projectName,
    recaptchaSiteToken,
    theme
  } = props;

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    recaptchaRef.current && recaptchaRef.current.execute();
  }, [recaptchaRef]);

  const [token, setToken] = useState<string | null>(null);
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const attemptAddSubscriber = async () => {
    const validEmail = /(.+)@(.+){2,}\.(.+){2,}/.test(subscriberEmail);

    if (!validEmail) {
      setErrorMessage('Please provide a valid email');
    } else {
      const url = '/subscribers.json';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'g-recaptcha-response-data': { subscribe: token },
          subscribed_object_type: 'Project',
          target_modal: "feedback-auth-modal",
          subscriber: { email: subscriberEmail },
          destination: window.location.href,
          override_block_robots: true
        }),
      });

      if (response.ok) {
        const body = await response.json();
        const params = new URLSearchParams({ email: body.subscriberEmail });
        window.Turbo.visit(`/updates/check_your_inbox?${params}`, { action: "replace" })
      } else {
        setErrorMessage('We were unable to create a subscription for you, please try again.')
      }
    }
  };

  useKeyPress('enter', () => email && handleSubmit());

  return (
    <div className="modal fade" id="feedback-auth-modal" tabIndex={-1} aria-labelledby="feedback-auth-modal">
      <div className="modal-dialog">
        <div className="modal-content feedback-modal px-3 py-3">
          <Space direction="vertical" className="feedback-auth h-100 w-100">
            <Row className="my-2">
              <Title level={3}>
                Get a magic link to leave feedback
              </Title>
            </Row>
            <Row className="my-4">
              <Text className="mb-2">
                {projectName} page subscribers can leave feedback and optionally stay informed on product changes. Get a magic link sent to your email that will sign you in instantly.
              </Text>
              <Input
                className="form-control my-2"
                placeholder="Email address"
                name="email"
                onChange={(e) => { setSubscriberEmail(e.target.value) }}
              />

              { !!errorMessage && (
                <Text className='my-2 error-message'>{ errorMessage }</Text>
              )}

              <ReCAPTCHA
                ref={recaptchaRef}
                onChange={(tkn) => setToken(tkn)}
                sitekey={recaptchaSiteToken}
                size={"invisible"}
              />

              <button
                type="submit"
                className="feedback-submit btn btn-primary btn-block my-2"
                onClick={attemptAddSubscriber}
                style={{
                  backgroundColor: theme.primaryColor,
                  borderColor: theme.primaryColor,
                  color: theme.whiteColor,
                }}
              >
                Submit
              </button>
            </Row>
          </Space>
        </div>
      </div>
    </div>
  );
}

export default AuthPromptModalBody;

AuthPromptModalBody.propTypes = {
  projectName: PropTypes.string,
  recaptchaSiteToken: PropTypes.string,
  theme: PropTypes.shape({
    optionalProperty: PropTypes.string,
  })
};
